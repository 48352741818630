import type { IconProps } from '.';

import classNames from 'classnames';

const Icon = ({
    size,
    name,
    className,
    backgroundColor,
    height,
    circle = false
}: IconProps) => (
    <span className={classNames('d-inline-flex icon', {
        'rounded-circle': circle,
        [`icon--${name}`]: name,
        [`icon--size__${size}`]: size,
        [`icon--height__${height}`]: height,
        [`${className}`]: className,
        [`bg-success`]: '#8bc003' === backgroundColor,
        [`bg-warning`]: '#ffc61b' === backgroundColor,
        [`bg-danger`]: '#dc3545' === backgroundColor
    })}></span>
)

export default Icon;
