import type { Aggregation } from "@/components/molecules/offcanvas-filter"

export default function fitlerQueryParams(searchParams: URLSearchParams, aggregations: Aggregation[] = [], ignore: string[] = []) {
    let params = Array.from(searchParams.entries())
    let ignoreFilter = ([...(process.env.REQUEST_FILTER_EXCLUDED ?? process.env.NEXT_PUBLIC_REQUEST_FILTER_EXCLUDED ?? '').split(',')])

    params = params.filter(([key, value]) => {
        let index = aggregations?.findIndex(({ name }: { name: string }) => name.toLocaleLowerCase() === key.toLocaleLowerCase())
        return value && (-1 !== index || ignore.includes(key)) && !ignoreFilter.includes(key)
    })

    params = params.map(([key, value]) => {
        let property = aggregations?.find(({ name }: { name: string }) => name === key)
        if (property) {
            let options = property.options.reduce((acc: any, cur: any) => {
                acc.push(cur.translated?.name ?? cur.name)
                return acc
            }, [])
            value = value.split('|').filter((val: string) => options.includes(val)).join('|')
        }
        return [key, value]
    })

    return new URLSearchParams(params);
}
