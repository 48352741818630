'use client' // Error boundaries must be Client Components

import '@styles/globals.scss'

import Image from '@/components/atoms/image'
import Link from "@/components/atoms/link";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Open_Sans } from 'next/font/google'

const openSans = Open_Sans({
  subsets: ['latin'],
  variable: '--font-open-sans',
  display: 'swap',
  adjustFontFallback: false
})

export default function GlobalError({
  error: _,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  return (
    // global-error must include html and body tags
    <html>
      <body className={`d-flex  flex-column  justify-content-center  vh-100  bg-gray-100  ${openSans.className}  ${openSans.variable}`}>
            <Container fluid>
                <Container className={'d-flex  align-items-center  shadow-sm  p-0  overflow-hidden'}>
                    <Row className={'flex-column  flex-lg-row  bg-white  w-100'}>
                        <Col className={'news-2column__col-left  position-relative  col-lg-6  pe-lg-0  mb-3  mb-lg-0  z-1'}>
                            <Image src={'/transpak_error.png'} alt={'Oh nein! Da ist etwas schiefgelaufen.'} className={'position-relative  position-lg-absolute  object-fit-contain  z-1  mw-100'} sizes={'717px'} fill />
                        </Col>
                        <Col className={'col-lg-6  ps-lg-4  py-5  mb-3  mb-lg-0'}>
                            <div className={'px-2  py-md-5  py-lg-3  fs-8  fw-semibold text-gray-900'}>
                                <h1 className={'H2-Headline-Default  fs-4  fs-lg-2'}>Oh nein! Da ist etwas schiefgelaufen.</h1>
                                <p>Es scheint, als hätten wir ein technisches Problem. Unsere Anwendung funktioniert momentan nicht wie erwartet. Wir arbeiten bereits daran, das Problem so schnell wie möglich zu beheben.</p>
                                <p>Was Sie jetzt tun können:</p>
                                <ul>
                                    <li>Versuchen Sie es in ein paar Minuten erneut.</li>
                                    <li>Zurück zur <Link href={'/'} className={'fw-bold'}>Startseite</Link> - Vielleicht finden Sie dort, was Sie benötigen.</li>
                                    <li>Kontaktieren Sie unseren <Link href={'/kontakt'} className={'fw-bold'}>Support</Link> - Wenn das Problem weiterhin besteht, lassen Sie uns bitte wissen, wie wir Ihnen helfen können.</li>
                                </ul>
                                <p>Wir entschuldigen uns für die Unannehmlichkeiten und danken Ihnen für Ihre Geduld.</p>
                                <p>Als kleine Wiedergutmachung schenken wir Ihnen 5 % Rabatt* auf Ihre nächste Bestellung mit dem Code <span className={'fw-bold'}>5SORRY</span>.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className={'fs-9  my-2  px-0  text-gray-500'}>
                *Nicht mit anderen Aktionen kombinierbar, 1x pro Kunde einlösbar, Maschinen, Geräte & Transporthilfen ausgenommen.
            </Container>
      </body>
    </html>
  )
}