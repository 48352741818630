'use client'

import { type AddToWishlistProps } from '.';

import Button from '@components/atoms/button';
import { useWishlistContext } from '@context/wishlist';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

const AddToWishlist = ({ productId, name, variant, className }: AddToWishlistProps) => {

    const { products, addProduct, removeProduct } = useWishlistContext()

    const [is, setIs] = useState<boolean>(false)

    const t = (str: string, _?: any): string => str

    useEffect(() => setIs(() => undefined !== products[productId]), [products, productId])

    return (
        <Button
            icon={is ? 'wishlist-checked' : 'wishlist'}
            iconWidth={'sm'}
            iconVariant={'secondary'}

            variant={'white'}

            className={classNames(null, { 
                [`${className}`]: className, 
                'add-to-wishlist  flex-row-reverse': 'hover' === variant,
                'px-2': undefined === className 
            })}

            onClick={() => false === is ? addProduct(productId) : removeProduct(productId)}

            aria-label={t('Add to Wishlist', { name })}
        >
            {'extended' === variant ? (
                <span>{t('Merkliste')}</span>
            ) : (
                'hover' === variant && (<span className={'d-inline-flex  fs-9  fw-bold'}>{t('Merkliste')}</span>)
            )}
        </Button>
    );
};

export default AddToWishlist;
