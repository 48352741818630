import parse, { Element, attributesToProps, domToReact, type HTMLReactParserOptions } from 'html-react-parser';
import Link from "@components/atoms/link";
import classNames from 'classnames';
import DOMPurify from 'isomorphic-dompurify';

const Content = ({ html, length }: { html: string, length: number | string }) => {

    const regex = new RegExp(String.fromCharCode(160))
    let str = html

    if ((('string' === typeof length && !isNaN(parseInt(length))) || length) && parseInt(length.toString()) < str.length) {
        str = str.substring(0, parseInt(length.toString())).trim() + '...'
    }

    const replaceAll = (html: string) => html
        .replaceAll('[wiki href=""]', '') // remove empty wiki links
        .replaceAll(/wiki\]\s*\[wiki/g, 'wiki]&nbsp;[wiki')
        .replaceAll('[wiki', '<a')
        .replaceAll('"]', '">')
        .replaceAll('[/wiki]', '</a>')

    const options: HTMLReactParserOptions = {
        trim: true,
        replace: domNode => {
            if (domNode instanceof Element && domNode.attribs) {
                if ('a' === domNode.name) {
                    const { children }: any = domNode
                    const { href, style, class: className, ...attribs }: any = attributesToProps(domNode.attribs)
                    const next: any = domNode.next

                    return (
                        <>
                            {(!!href && !href.startsWith('https')) ? (
                                <Link href={href} className={classNames(null, { [`${className}`]: className })} {... (!!style && { style })} {...attribs}>
                                    {domToReact(children, options)}
                                </Link>

                            ) : (
                                <a href={href} className={classNames(null, { [`${className}`]: className })} {... (!!style && { style })} {...attribs}>
                                    {domToReact(children, options)}
                                </a>
                            )}
                            {next?.data?.match(regex) && <>&nbsp;</>}
                        </>
                    )
                }
            }
        }
    }

    return parse(DOMPurify.sanitize(replaceAll(str), { /*FORBID_ATTR: ['style'],*/ FORCE_BODY: true }), options)
}

export default Content
