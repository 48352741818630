'use client'

import type { HideableProps } from "."

import { useSession } from "next-auth/react"

const Hideable = ({ children, hideable = false }: HideableProps) => {
    const { data: session } = useSession()

    if (hideable && true === session?.user?.group?.displayGross) {
        return null
    }

    return children
}

export default Hideable
