// src/components/molecules/delivery/Delivery.tsx
import type { DeliveryProps } from '.';

import Icon from '@components/atoms/icon';
import classNames from 'classnames';

const Delivery = ({ deliveryTime, className, showDeliveryTime = false, showDeliveryTimeInMobileOnly = true, showLabel = false, showPopUp = true, variant = "default" }: DeliveryProps) => {

    if (null === deliveryTime) {
        return null
    }

    const name = deliveryTime?.translated?.name ?? deliveryTime.name
    const customFields = deliveryTime?.translated?.customFields ?? deliveryTime.customFields

    const nameParts = name.split(',')

    return (
        <span className={classNames('delivery  position-relative  d-inline-flex  align-items-center  cursor--pointer', { [`${className}`]: className })}>
            {customFields && (
                <Icon
                    name={customFields?.delivery_time_icon_type}
                    circle={true}
                    size={'sm'}

                    backgroundColor={customFields?.delivery_time_color}
                    className={classNames('icon--white', {
                        [`bg-${customFields?.delivery_time_icon_variant}`]: customFields?.delivery_time_icon_variant
                    })}
                />
            )}
            {!showDeliveryTime ? (
                <>
                    {(true === showLabel && variant == 'default') && (
                        <div
                            className={classNames('d-flex  d-xl-none  lh-1', {
                                [`text-success`]: '#8bc003' === customFields?.delivery_time_color,
                                [`text-warning`]: '#ffc61b' === customFields?.delivery_time_color,
                                [`text-danger`]: '#dc3545' === customFields?.delivery_time_color,
                                [`text-custom-f36e08`]: '#f36e08' === customFields?.delivery_time_color
                            })}
                            {...(!['#8bc003', '#ffc61b', '#dc3545', '#f36e08'].includes(customFields?.delivery_time_color)) && {
                                style: { color: customFields?.delivery_time_color }
                            }}
                        >
                            {nameParts[0] ?? name}
                        </div>
                    )}
                </>
            ) : (
                <div className={classNames('fw-semibold', {
                    'ms-1': customFields,
                    'd-inline-flex  d-xl-none': showDeliveryTimeInMobileOnly,
                    'd-inline-flex': !showDeliveryTimeInMobileOnly,
                    [`text-success`]: '#8bc003' === customFields?.delivery_time_color,
                    [`text-warning`]: '#ffc61b' === customFields?.delivery_time_color,
                    [`text-danger`]: '#dc3545' === customFields?.delivery_time_color,
                    [`text-custom-f36e08`]: '#f36e08' === customFields?.delivery_time_color
                })}
                    {...(!['#8bc003', '#ffc61b', '#dc3545', '#f36e08'].includes(customFields?.delivery_time_color)) && {
                        style: { color: customFields?.delivery_time_color }
                    }}
                >
                    {name}
                </div>
            )}
            {showPopUp && (
                <div className={'delivery__popup  z-3  p-2  me-1  bg-white  shadow  rounded-2  border  border-gray-200  text-start'}>
                    <span className={'d-block  text-uppercase  fs-6  fw-bold  lh-sm  mb-1'} style={{ color: customFields?.delivery_time_color }}>{nameParts[0] ?? name}</span>
                    {nameParts[1] && <span className={'fw-semibold  fs-9'}>{nameParts[1]}</span>}
                </div>
            )}
        </span>
    )
};

export default Delivery;
