'use client'

import type { NewsletterWrapperProps } from '.';

import { object, string } from 'yup';
import useOrigin from '@hooks/useOrigin';
import { toast } from 'react-toastify';
import { useLocale } from 'next-intl';
import { Children, cloneElement, useState } from 'react';
import { useSession } from 'next-auth/react';
import Container from 'react-bootstrap/Container';

const NewsletterWrapper = ({ children, wrap = false }: NewsletterWrapperProps) => {
    const { data: session }: any = useSession()

    const origin = useOrigin()
    const locale = useLocale()

    const t = (str: string) => str

    const [loading, setLoading] = useState<boolean>(false)
    const [sent, setSent] = useState<boolean>(false)

    if (!(undefined === session || null === session || true !== session?.user?.group?.displayGross)) {
        return null
    }

    const onSubmit = async ({ company, onSuccess, ...values }: any) => {
        setLoading(true)

        try {
            const res = await fetch('/api/newsletter/subscribe?locale=' + locale, {
                method: "POST",
                body: JSON.stringify({ ...values, title: false === session?.user?.group?.displayGross ? company : '', option: 'subscribe', storefrontUrl: origin })
            });

            if (204 === res.status) {
                setSent(true)
                onSuccess?.()
            } else {
                const json = await res.json();
                json?.errors?.forEach(({ detail }: any) => {
                    toast.error(detail)
                })
            }
            setLoading(false)
        } catch (err: any) {
            toast.error(err)
            setLoading(false)
        }
    }


    let validationSchema = object().shape({
        firstName: string().required(t('Pflichtfeld')),
        lastName: string().required(t('Pflichtfeld')),
        company: string().required(t('Pflichtfeld')),
        email: string().required(t('Pflichtfeld')).email(t('Bitte geben Sie eine valide E-Mail-Adresse ein.')),
        zipCode: string()
            .required(t('Pflichtfeld'))
            .matches(/^[0-9]+$/, t('Nur Ziffern sind erlaubt.'))
            .when([], {
                is: () => 'de-DE' === locale,
                then: () => string()
                    .min(5, t('Eingabe muss genau 5 Ziffern lang sein.'))
                    .max(5, t('Eingabe muss genau 5 Ziffern lang sein.')),
                otherwise: () => string()
                    .min(4, t('Eingabe muss genau 4 Ziffern lang sein.'))
                    .max(4, t('Eingabe muss genau 4 Ziffern lang sein.'))
            }),
    });

    if (undefined === session || null === session || true !== session?.user?.group?.displayGross) {
        validationSchema = validationSchema.concat(object().shape({
            company: string().required(t('Pflichtfeld')),
        }))
    }

    const render = () => Children.map(children, (child) => {
        return cloneElement(child as React.ReactElement<any>, { sent, loading, onSubmit, validationSchema })
    })

    return (true === wrap ? (
        <Container fluid className={'bg-footer-light'}>
            {render()}
        </Container>
    ) : (
        render()
    ))
}

export default NewsletterWrapper
