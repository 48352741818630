import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/context/product-media.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/context/product-purchase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/context/product-table.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/react-bootstrap/esm/ButtonGroup.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/Col.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/react-bootstrap/esm/FormControl.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/react-bootstrap/esm/Nav.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/Row.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/react-bootstrap/esm/Spinner.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/react-bootstrap/esm/Table.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/button/SubmitButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/cookiefirst/CookieFirst.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/hideable/Hideable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/no-ssr/NoSsr.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/atoms/price/Client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/tax/Tax.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/trusted-shops/TrustedShops.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/video/Video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/add-to-compare/AddToCompare.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/add-to-wishlist/AddToWishlist.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/image-slider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/media/cover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/media/preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/single-view/add-to-cart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/single-view/more-information.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/single-view/price-buttongroup-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/single-view/price.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/single-view/qty-add-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/single-view/qty-input.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/single-view/qty-remove-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/single-view/qty-to-next-graduation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/variants-table/add-all-to-cart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/variants-table/add-to-cart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/variants-table/row-palette.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/variants-table/row-price.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/variants-table/row-qty.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/variants-table/row-sum.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/variants-table/show-variant-table-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-details/variants-table/sum.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/molecules/block/renderer/product-listing/availability.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-listing/limiter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/product-listing/sorter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/block/renderer/set-last-seen.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/cmscollapse/CmsCollapseCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/cmscollapse/CmsCollapseWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/custom-form/CustomForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/filter-button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/multi-carousel/MultiCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/newsletter-subscribe/NewsletterSubscribe.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/newsletter-wrapper/NewsletterWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/offcanvas-filter/Filter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/pagination/Pagination.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/organisms/product-finder/ProductFinderClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/third-parties/facebook/Facebook.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/third-parties/pageview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/third-parties/simptrack/Simptrack.tsx");
