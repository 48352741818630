import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback } from "react";

export default function useQueryParams() {
    const searchParams = useSearchParams();
    const router = useRouter();
    const pathname = usePathname();

    const createQueryString = useCallback(
        (name: string, value: string | number | null, concat: boolean = false) => {
            const params = new URLSearchParams(searchParams);

            if (null === value) {
                params.delete(name)
            } else {
                params.delete('page')
                
                if (concat) {
                    let param: string | null = params.get(name)
                    let setter: string[] = [value.toString()]

                    if (param) {
                        setter = setter.concat(param.split('|'))
                    }

                    params.set(name, setter.join('|'));
                } else {
                    params.set(name, value.toString());
                }
            }

            return params.toString();
        },
        [searchParams],
    );

    const setPathName = (pathname: string, queryParams: { name: string, value: string | number }[], scroll: boolean = false) => {
        const params = new URLSearchParams(searchParams);

        queryParams.forEach(({ name, value }) => params.set(name, value.toString()))

        router.push(`${pathname}?${params.toString()}`, { scroll });
    };

    const setQueryParam = (queryName: string, value: string | number | null, concat: boolean = false, scroll: boolean = false) => {
        const queryString = createQueryString(queryName, value, concat)
        router.push(`${pathname}${queryString ? '?' + queryString : ''}`, { scroll });
    };

    const setQueryParams = (queryParams: { name: string, value: string | number }[], scroll: boolean = false) => {
        const params = new URLSearchParams(searchParams);

        queryParams.forEach(({ name, value }) => params.set(name, value.toString()))
        
        router.push(`${pathname}?${params.toString()}`, { scroll });
    };

    const removeQueryParamValue = (queryName: string, value: string | number, scroll: boolean = false) => {
        const params = new URLSearchParams(searchParams);

        const param: string | null = params.get(queryName)
        if (param) {
            const values = param.split('|')?.filter((paramValue: string) => paramValue !== value)
            if (values.length) {
                params.set(queryName, values.join('|'));
            } else {
                params.delete(queryName)
            }
        }

        router.push(`${pathname}?${params.toString()}`, { scroll });
    };

    const removeAllQueryParams = (removeable?: string[], scroll: boolean = false) => {
        const params = new URLSearchParams(searchParams);

        removeable?.forEach((key) => params.delete(key))

        router.push(`${pathname}?${params.toString()}`, { scroll });
    };

    return { queryParams: searchParams, pathname, createQueryString, setPathName, removeQueryParamValue, removeAllQueryParams, setQueryParam, setQueryParams };
}
